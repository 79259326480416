/* eslint-disable @typescript-eslint/no-explicit-any */
import { JSXElement } from "solid-js";

import content from "~/data/content.json";
import { ContentBody, ArticlesList } from "../(home)/ui/Articles";

const workdata = content.workData;
const samplesData = content.samplesData;
function Projects(): JSXElement {
  return (
    <article
      class="jl-centered fluid-px fluid-py fl-xs-s md:fl-m-l px-5"
      id="about-personal"
    >
      <div class="relative additive-spacing additive-spacing--lg w-full">
        <h3 class="text-xl ff-serif px-5">Tools/Projects</h3>
        <section class="grid-auto-fit mx-auto cols-1 lg:cols-2 gap-3">
          <div class="jl-start additive-spacing additive-spacing--lg">
            <ContentBody title="Published">
              <List data={workdata.children} />
            </ContentBody>
          </div>
          <ContentBody title="Misc/Archive">
            <List data={samplesData} />
          </ContentBody>
        </section>
      </div>
    </article>
  );
}

function List(props: { data: any }) {
  return (
    <ArticlesList
      articles={props.data}
      // @ts-expect-error ignore
      renderBody={(item) => <>{item.text}</>}
    />
  );
}
export default Projects;
