import { Link, Title } from "@solidjs/meta";
import AboutView from "~/components/specific/about/About";
import { sitepaths } from "~/configs/paths";

const meta = {
  title: `${sitepaths.about.title} | Jonathan Lau`,
  canonical: `${import.meta.env.VITE_APP_BASE_URL}${sitepaths.about.path}`,
};
const About = () => {
  return (
    <>
      <>
        <Title>{meta.title}</Title>
        <Link rel="canonical" href={`${meta.canonical}`} />
      </>
      <AboutView />
    </>
  );
};

export default About;
