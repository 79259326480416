import { For, JSXElement } from "solid-js";
import { avatarUrls } from "~/configs/paths";

function HeroArticle(): JSXElement {
  return (
    <article
      class="jl-start border-ink fluid-px fluid-py fl-xs-s md:fl-m-l lg:fl-l-xl surface3"
      id="about-me"
    >
      <div class="relative additive-spacing additive-spacing--lg">
        <div class="grid-auto-fit cols-1 lg:grid-cols-2 mx-auto">
          <div class="jl-centered additive-spacing additive-spacing--lg p-5">
            <ContentBody />
          </div>
          <ul class="max-w-full relative flex flex-wrap md:flex-nowrap items-center justify-center">
            <For each={avatarUrls}>
              {(item) => (
                <li class="text-xs p-3 md:p-1">
                  <picture>
                    <source
                      media={`(min-width: ${item.size * 2 + 100}px)`}
                      srcset={item.url}
                    />
                    <source
                      media={`(max-width: ${item.size * 2 + 99}px)`}
                      srcset={`${item.url.split("/upload/")[0]}/upload/q_auto:low,e_pixelate:${Math.max(20, 512 / item.size)}/${item.url.split("/upload/")[1]}`}
                    />
                    <img
                      width={item.size}
                      height={item.size}
                      class={`about-profile z-2 bg-transparent object-cover hide-on-small-screen-${item.size}`}
                      src={item.url}
                      alt={`avatar-${item.size}x${item.size}`}
                      crossOrigin="anonymous"
                    />
                  </picture>
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
    </article>
  );
}

function ContentBody() {
  return (
    <div class="px-5">
      <h2 class="text-pretty tracking-tight text2 text-3xl lg:text-4xl">
        Crafting <span class="max-lg:whitespace-pre">High-Performance</span>{" "}
        <span class="max-lg:whitespace-nowrap">Web Experiences</span>
      </h2>
      <p class="pretty-text mt-6 text-lg leading-8 ff-sans-serif text1 font-light">
        Hey, I'm <span class="ff-cursive text4">Jonathan</span>
        ,&nbsp;or{" "}
        <span class="ff-cursive text4" style={{ "--color": "var(--elevate2)" }}>
          Jon
        </span>{" "}
        for short. I'm a{" "}
        <span class="ff-cursive em-underline decoration-dotted text4 lg:whitespace-nowrap">
          front-end engineer
        </span>{" "}
        with 8+ yoe in individual and team lead roles, collaborating with
        product, platform, and marketing teams.
        <br />
        <br />I focus on web standards-compliant experiences and specialize in
        performance, helping teams build user-centric products that are both
        functional and performant.
      </p>
    </div>
  );
}

export default HeroArticle;
