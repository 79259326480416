import { For, JSXElement } from "solid-js";
export function ContentBody(props: { title: string; children: JSXElement }) {
  return (
    <div class="mx-5 w-full">
      <h4>{props.title}</h4>
      <br />
      {props.children}
    </div>
  );
}
// @ts-expect-error ignore
export function ArticlesList(props) {
  return (
    <section class="jl-start additive-spacing additive-spacing--lg lg:mr-8">
      <ul class="flow flow-list h-full space-xs-s">
        <For each={props.articles}>
          {(item) => {
            return (
              <li class="w-full">
                <a href={item.url} target="_blank" class="jl-start">
                  <article class="flex justify-between items-center gap-2 ff-serif sm:gap-4 flex-wrap lg:flex-nowrap">
                    <div>
                      <h5
                        data-area-label="title"
                        class="text2 ff-serif text-ellipsis overflow-hidden mx-w-md lg:max-w-lg pr-4"
                      >
                        {item.title}
                      </h5>
                      {props.renderBody && (
                        <p class="font-light text-sm text3 ff-sans-serif">
                          {props.renderBody(item)}
                        </p>
                      )}
                    </div>
                    {item.date && (
                      <time
                        data-area-label="date"
                        class="text3"
                        datetime={String(item.date)}
                      >
                        {item.date}
                      </time>
                    )}
                  </article>
                </a>
              </li>
            );
          }}
        </For>
      </ul>
    </section>
  );
}
