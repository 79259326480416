import { JSXElement } from "solid-js";
import { mediumFeatures, popularPosts } from "~/configs/paths";
import { ContentBody, ArticlesList } from "../(home)/ui/Articles";
function RecentUpdates(): JSXElement {
  return (
    <article
      class="jl-centered fluid-px fluid-py fl-xs-s md:fl-m-l px-5"
      id="about-personal"
    >
      <div class="relative additive-spacing additive-spacing--lg w-full">
        <h3 class="text-xl ff-serif px-5">Curated Writings</h3>
        <section class="grid-auto-fit mx-auto cols-1 lg:cols-2 gap-3">
          <div class="jl-start additive-spacing additive-spacing--lg">
            <ContentBody title="Blog Highlights">
              <PopularArticles />
            </ContentBody>
          </div>
          <ContentBody title="Featured Articles">
            <MediumArticles />
          </ContentBody>
        </section>
      </div>
    </article>
  );
}

function MediumArticles() {
  return (
    <ArticlesList
      articles={mediumFeatures}
      // @ts-expect-error ignore
      renderBody={(item) => (
        <>
          in {item.publisher}
          {item.likes && (
            <span class="text-xs"> &nbsp;&nbsp;&#9829;&nbsp;{item.likes}</span>
          )}
        </>
      )}
    />
  );
}

function PopularArticles() {
  return (
    <ArticlesList
      articles={popularPosts}
      // @ts-expect-error ignore
      renderBody={(item) => <>{item.highlight}</>}
    />
  );
}
export default RecentUpdates;
