import { RESUME_URL } from "~/configs/paths";
import HeroArticle from "./HeroArticle";
import RecentUpdates from "./RecentUpdates";
import FaArrowRightLong from "~/assets/svg/fa-arrow-right-long.svg?component-solid";
import Projects from "./Projects";

export default function AboutView() {
  return (
    <div
      id="skiplink-content"
      class="flex flex-col outer`-mx size-step-1 xl:size-step-0 overflow-hidden"
    >
      {/* I’m a jobbing web developer with a firm belief that the web is a */}
      {/* revolutionary communication mechanism and should be available to all */}
      <HeroArticle />
      <div class="jl-centered my-20">
        <p class="pretty-text ff-sans-serif outer-mx size-step-2 text-lg">
          This website is maintained by me,{" "}
          <strong class="ff-cursive text4">Jonathan Lau.</strong> View my{" "}
          <a
            data-defer
            aria-label="view resume in pdf format"
            href={RESUME_URL}
            style={{ "--color": "var(--elevate3)" }}
            class="jl-underline ff-cursive text4 cursor-highlight-pointer-dark hover:wavy-underline hover:text-neutral-55"
          >
            full résumé.
            <span
              aria-hidden="true"
              class="ff-mono text-lg text-center"
              aria-label="Arrow indicating link to resume"
            >
              <FaArrowRightLong
                class="inline pl-1"
                style={{ fill: "var(--kiwi-100)" }}
              />
            </span>
          </a>
        </p>
      </div>
      <RecentUpdates />
      <Projects />
      {/* <RootAccessibility /> */}
      {/* <RootOpportunities /> */}
      {/* <PersonalArticle /> */}
      {/* <Misc /> */}
      {/* <RootLinks /> */}
      {/* <div role="list" class="flex flex-col jl-grid">
        <For each={gridItems}>
            {({ order, rowSpan, colSpan, child }) => (
              <GridCardItem order={order} rowSpan={rowSpan} colSpan={colSpan}>
                {child}
              </GridCardItem>
            )}
          </For>
        </div> */}
    </div>
  );
}
